import logo from "../assets/logo.png";
import {useEffect, useState} from "react";
import {getTours, Tour} from "../services/tour-service";
import {Card, List} from "antd";
import Meta from "antd/es/card/Meta";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

import './arrow.css';
import {useNavigate} from "react-router-dom";
import {useTokenAuth} from "../hooks/token-auth-hook";
import {HomeButton} from "../components/button/HomeButton";
import {showErrorNotification} from "../components/notification";
import TextArea from "antd/lib/input/TextArea";

export const TourMenu = ({}) => {
    const title = 'Factory Tour'.toUpperCase();
    useTokenAuth();
    const sizePerPage = 4;
    const [tours, setTours] = useState<Tour[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        getTours().then((data) => {
            if (data.status === 200) {
                setTours(data.data);
            }
        }).catch((e) => {
            console.log(e);
            if (e.response.status === 403) {
                showErrorNotification("Invalid entry! Your token might be not activated yet! Please contact admin to obtain the access token");
                return;
            }

            showErrorNotification(e.message);
        })
    }, []);

    const leftArrowClicked = () => {

    };
    const rightArrowClicked = () => {

    };
    const leftArrowDisabled = () => {
       return true
    };
    const rightArrowDisabled = () => {
        return true
    };
    return <div style={{
        // backgroundImage: `url(${background})`,
        backgroundSize: 'cover',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }}>
        <video id="background-video" autoPlay loop muted>
            <source src={require('../assets/background.mp4')} type="video/mp4"/>
        </video>
        <img src={logo} alt='logo' style={{width: '20%'}}/>
        <div
            style={{
                width: '50%',
                textAlign: 'center',
                background: 'rgb(237,237,237)',
                margin: 30,
                boxShadow: "7px 6px 12px 0px lightgrey",
                borderRadius: 20
            }}
        >
            <h1 style={{fontSize: 40}}>{title}</h1>
        </div>
        <div style={{
            display: 'flex',
            flexDirection: 'row'
        }}>
            {tours.length > sizePerPage && <LeftOutlined
                style={{fontSize: '200px', width: '3vw'}}
                className={`arrow ${leftArrowDisabled() && 'arrow-disabled'}`}
                onClick={leftArrowClicked}
            />}
            <List grid={{gutter: 25, column: Math.min(4, tours.length)}}
                  dataSource={tours.slice(0, Math.min(4, tours.length))} renderItem={(tour) => {
                return <List.Item><Card
                    hoverable={true}
                    onClick={() => {
                        navigate(`/tour/${tour.id}`)
                    }}
                    style={{
                        width: '250px',
                        // padding: '15px 24px',
                        borderRadius: 13,
                        boxShadow: '5px 7px 9px lightgrey'
                    }}
                    cover={<div style={{padding: '10px 10px 0 10px'}}>
                        <img
                            alt={tour.name}
                            src={tour.url}
                            style={{
                                objectFit: 'cover',
                                width: '230px',
                                height: '230px',
                            }}
                        />
                    </div>}
                >
                    {/*<Meta style={{textAlign: 'center'}} title={tour.name}/>*/}
                    <Meta style={{textAlign: 'center'}} title={<TextArea rows={2} value={tour.name} style={{
                        padding: '4px 0',
                        whiteSpace: 'pre-line',
                        border: 'none',
                        width: '100%',
                        resize: 'none',
                        outline: 'none',
                        lineHeight: '1.1',
                        cursor: 'pointer',
                        fontSize: '16px',
                        textAlign: 'center',
                        verticalAlign: 'middle',
                        fontWeight: '800',
                        paddingTop: '10',
                    }}
                    />}/>

                </Card></List.Item>;
            }}/>
            {tours.length > sizePerPage &&
                <RightOutlined
                    style={{fontSize: '200px', width: '3vw'}}
                    className={`arrow ${rightArrowDisabled() && 'arrow-disabled'}`}
                    onClick={rightArrowClicked}/>
            }
        </div>

        <HomeButton style={{left: -7, width: 136, fontSize: 40, padding: '10px 0'}}/>

    </div>
}
