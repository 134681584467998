import React, {useEffect, useState} from "react";
import {InboxOutlined} from '@ant-design/icons';
import {Form, FormInstance, Input, Modal, Upload} from "antd";
import env from "react-dotenv";
import {Scene, SceneCreate} from "../../../services/scene-service";
import TextArea from "antd/lib/input/TextArea";
import StorageDragger from "../../../components/upload/storage-dragger";
import {TextToSpeechPlayer} from "../../../components/voice/text-to-speech-player";
import {TextToSpeechInput} from "../../../components/voice/text-to-speech-input";
import {uploadAudio} from "../../../services/audio-upload-service";

const {Dragger} = Upload;

const SceneModal = (
    {
        open,
        locationId,
        scene,
        onCreate = (_) => {
        },
        onCancel = () => {
        }
    }) => {

    const formRef = React.useRef(null);

    const [file, setFile] = useState();
    const [sceneData, setSceneData] = useState();
    const [text, setText] = useState('');

    useEffect(() => {
        if (scene) {
            formRef.current?.resetFields();
            setFile(scene.url);
            setSceneData(scene);
            setText(scene.scripts ?? '');
            formRef.current?.setFieldsValue(
                {
                    ...scene
                }
            )
        }
    }, [scene, locationId]);


    const switchUploadContent = () => {
        return (file) ?
            <img alt={'img'} src={file} style={{objectFit: 'contain', width: '100%'}}/>
            :
            <>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined/>
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>

            </>
            ;
    }

    return <Modal open={open}
                  onOk={() => {
                      const scripts = formRef.current?.getFieldValue('scripts');
                      formRef.current?.validateFields()
                          .then(() => {
                              if (scripts === sceneData.scripts) {
                                  console.log('same')
                                  return Promise.resolve();
                              }

                              if (!scripts) {
                                  return Promise.resolve();
                              }

                              return uploadAudio(scripts);
                          })
                          .then((url) => {
                              const uploadUrl = url? url : scripts? sceneData.scriptsUrl : '';
                              onCreate({
                                  name: formRef.current?.getFieldValue('name'),
                                  id: sceneData?.id,
                                  url: file ?? '',
                                  locationId: locationId ?? '',
                                  scripts: scripts,
                                  scriptsUrl: uploadUrl
                              });
                          })
                  }}
                  onCancel={() => {
                      onCancel();
                  }}

    >
        <StorageDragger
            type={'scene'}
            onFileUploaded={(src) => {
                setFile(src);
            }}
        >
            {switchUploadContent()}
        </StorageDragger>
        <Form style={{padding: '20px 0'}}
              ref={formRef}
        >
            <Form.Item name='name' label='Name' rules={[{required: true}]}>
                <TextArea rows={2} autoSize={false} style={{resize: 'none'}}/>
            </Form.Item>
            <TextToSpeechInput text={text} name='scripts' label='Scripts'/>
        </Form>


    </Modal>
}

export default SceneModal;