
export const BottomBar = ({children, style, absolute = false}) => {
    return <>
        <div style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            padding: '5px 0px',
            backgroundColor: 'rgba(150,150,150,0.8)',
            position: absolute? 'absolute' : 'relative',
            bottom: 0,
            width: '100%',
            ...style
        }}>
            {children}
        </div>
    </>;
}