import React, {useEffect, useRef, useState} from "react";
import {getUploadUrl, uploadfile} from "../../services/file-service";
import {Spin} from "antd";


const StorageUpload = (
    {
        type,
        children,
        onFileUploaded = (url) => {},
        ...props
    }
) => {
    const uploadRef = useRef();

    useEffect(() => {
        uploadRef?.current?.addEventListener('change', async (e) => {
            const data = e.target.files;
            if (data.length === 0)
                return;

            const file = data[0];

            await uploadFileRaw(file, onFileUploaded);
        }, false);

        return uploadRef?.current?.removeEventListener('change', (e) => {
                console.log(e)
            }, false
        );
    }, []);

    const uploadFileRaw = async (file, callback) => {
        const uploadUrl = (await getFileUploadUrl(file)).data.url;

        uploadFile(file, uploadUrl)
            .then(data => {
                const url = new URL(uploadUrl);
                url.search = "";
                callback(url.toString());
            });
    }

    const getFileUploadUrl = async (file) => {
        return getUploadUrl({
            mimeType: file.type,
            type: type
        });
    }

    const uploadFile = async (file, url) => {
        return uploadfile(file, url);
    }

    return <>
        <div
            style={{width: 0}}
             onClick={() => {
                 uploadRef?.current?.click();}}
        >
            {children}
        </div>
        <form style={{display: "none"}}>
            <input ref={uploadRef} name={'file'} type={"file"}>
            </input>
        </form>
    </>

}

export default StorageUpload;
