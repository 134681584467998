import React, {useEffect, useState} from "react";
import {Form, FormInstance, Input, Modal, Upload} from "antd";
import {Location, LocationCreate} from "../../../services/location-service";
import StorageDragger from "../../../components/upload/storage-dragger";
import TextArea from "antd/lib/input/TextArea";
const LocationModal = (
    {
        open,
        tourId,
        location,
        onCreate = (_) => {
        },
        onCancel = () => {
        }
    }
        :
        {
            open: boolean,
            tourId?: string,
            location?: Location,
            onCreate: (location: LocationCreate) => void,
            onCancel: () => void
        }) => {

    const formRef = React.useRef<FormInstance>(null);

    const [file, setFile] = useState<string>();
    const [locationData, setLocationData] = useState<LocationCreate>();

    useEffect(() => {
        if (location) {
            formRef.current?.resetFields();
            setFile(location.url);
            setLocationData(location);
            formRef.current?.setFieldsValue(
                {
                    ...location
                }
            )
        }
    }, [location]);



    const switchUploadContent = () => {
        return (file) ?
            <img alt={'img'} src={file} style={{ objectFit: 'contain', width: '100%'}}/>
            :
            <>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </>
            ;
    }

    return <Modal open={open}
                  onOk={() => {
                      formRef.current?.validateFields().then(() => {
                          onCreate({
                              name: formRef.current?.getFieldValue('name'),
                              id: locationData?.id,
                              url: file?? '',
                              tourId: tourId??''
                          });
                      })
                  }}
                  onCancel={() => {
                      onCancel();
                  }}

    >
        <StorageDragger
            type={'location'}
            resize={{required: true, maxWidth: 1920, maxHeight: 1080, quality: 20}}
            onFileUploaded={(src) => {
                setFile(src);}}
        >
            {switchUploadContent()}
        </StorageDragger>
        <Form style={{padding: '20px 0'}}
              ref={formRef}
        >
            <Form.Item name='name' label='Name' rules={[{required: true}]}>
                <Input/>
            </Form.Item>
        </Form>


    </Modal>
}

export default LocationModal;