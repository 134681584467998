import {Form, Input, Modal} from "antd";
import React, {useEffect, useState} from "react";
import StorageDragger from "../../../components/upload/storage-dragger";
import TextArea from "antd/lib/input/TextArea";
import {TextToSpeechPlayer} from "../../../components/voice/text-to-speech-player";
import {TextToSpeechInput} from "../../../components/voice/text-to-speech-input";
import {uploadAudio} from "../../../services/audio-upload-service";

const TourModal = ({
                       open,
                       tour,
                       onCreate = (_) => {
                       },
                       onCancel = () => {
                       }
                   }) => {
    const formRef = React.useRef(null);

    const [file, setFile] = useState();
    const [tourData, setTourData] = useState();
    const [text, setText] = useState()

    useEffect(() => {
        if (tour) {
            formRef.current?.resetFields();
            setFile(tour.url);
            setTourData(tour);
            formRef.current?.setFieldsValue(
                {
                    ...tour
                }
            )
            setText(tour.scripts)
        }
    }, [tour]);

    const switchUploadContent = () => {
        return (file) ?
            <img alt={'img'} src={file} style={{ objectFit: 'contain', width: '100%'}}/>
            :
            <>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
            </>
            ;
    }

    return <Modal open={open}
                  onOk={() => {
                      const scripts = formRef.current?.getFieldValue('scripts')?? '';
                      formRef.current?.validateFields()
                          .then(() => {
                              if (scripts === tourData.scripts) {
                                  console.log('same')
                                  return Promise.resolve();
                              }

                              if (!scripts) {
                                  return Promise.resolve();
                              }

                              return uploadAudio(scripts);
                          })
                          .then((url) => {
                              const uploadUrl = url? url : scripts? tourData.scriptsUrl : '';
                              onCreate({
                              name: formRef.current?.getFieldValue('name'),
                              id: tourData?.id,
                              url: file?? '',
                              scripts: scripts,
                              scriptsUrl: uploadUrl
                          });
                      })
                  }}
                  onCancel={() => {
                      onCancel();
                  }}

    >
        <StorageDragger
            type={'location'}
            resize={{required: true, maxWidth: 1920, maxHeight: 1080, quality: 20}}
            onFileUploaded={(src) => {
                setFile(src);}}
        >
            {switchUploadContent()}
        </StorageDragger>
        <Form style={{padding: '20px 0'}}
              ref={formRef}
        >
            <Form.Item name='name' label='Name' rules={[{required: true}]}>
                <Input/>
            </Form.Item>
            <TextToSpeechInput text={text} name='scripts' label='Scripts'/>
        </Form>


    </Modal>
}

export default TourModal;