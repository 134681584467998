import {Form} from "antd";
import TextArea from "antd/lib/input/TextArea";
import {TextToSpeechPlayer} from "./text-to-speech-player";
import React, {useEffect, useRef, useState} from "react";

export const TextToSpeechInput = ({text, name, label}) => {
    const textAreaRef = useRef(null);
    const [typingStopped, setTypingStopped] = useState(true)
    const [typingText, setTypingText] = useState()
    const [audioText, setAudioText] = useState();

    useEffect(() => {
        setAudioText(text)
    }, [text]);

    useEffect(() => {
        // Reset typingStopped whenever inputValue changes
        setTypingStopped(false);

        // Set a timer to detect when typing stops
        const timer = setTimeout(() => {
            setTypingStopped(true); // User has stopped typing
            console.log(typingText);
            setAudioText(typingText);
        }, 500); // 500ms delay

        // Cleanup timeout on each input change or component unmount
        return () => {
            clearTimeout(timer);
        };
    }, [typingText]);


    return <>
        <Form.Item name={name} label={label}>
            <TextArea ref={textAreaRef} rows={6} autoSize={false} style={{resize: 'none'}} value={typingText} onChange={(event) => {
                setTypingText(event?.target?.value??'')
            }}/>
        </Form.Item>
        <TextToSpeechPlayer text={audioText} autoplay={false}/>
    </>
}