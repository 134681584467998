import axios from "axios";

export const handleSpeakBase64 = async (text) => {
    const data = await handleSpeakBase(text);
    if (data) {
        return (`data:audio/mp3;base64,${data}`);
    }
};

export const handleSpeakFile = async (text) => {
    const data = await handleSpeakBase(text);
    if (!!data) {
        return await base64ToFile(data, `${crypto.randomUUID()}.mp3`);
    }
}


function base64ToFile(base64String, fileName) {
    const byteCharacters = atob(base64String);
    const byteArrays = [];
    const sliceSize = 512;

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new File(byteArrays, fileName, { type: 'audio/mp3' });
}

export const handleSpeakBase = async (text) => {
    const apiKey = 'AIzaSyAQqo0tnWg_cGsplzAYP0saR84FCnXZpls'; // Replace with your API key
    const url = `https://texttospeech.googleapis.com/v1/text:synthesize?key=${apiKey}`;

    const data = {
        input: {text},
        voice: { languageCode: 'en-GB', ssmlGender: 'MALE', name: 'en-GB-News-J' },
        audioConfig: { audioEncoding: 'MP3' },
    };

    if (!text) {
        return;
    }

    try {
        const response = await axios.post(url, data);
        return response.data.audioContent;
    } catch (error) {
        console.error('Error with TTS request', error);
        // throw error;
    }
}