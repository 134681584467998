import {useEffect, useRef, useState} from "react";
import {handleSpeakBase64} from "../../services/google/text-to-speech";

export const TextToSpeechPlayer = ({style = {}, text, autoplay = true, size = 25}) => {
    const audioRef = useRef();
    const [audioUrl, setAudioUrl] = useState()

    useEffect(() => {
        console.log(text)
        handleSpeakBase64(text).then(audio => setAudioUrl(audio))
    }, [text]);

    return <>
        <div style={{...style}}>
            <audio ref={audioRef} controls="true" src={audioUrl} autoPlay={autoplay}/>
        </div>
    </>
}