import {getUploadUrl, uploadfile} from "./file-service";
import {handleSpeakBase64, handleSpeakFile} from "./google/text-to-speech";

export const uploadAudio = async (text) => {
    const uploadUrl = (await getUploadUrl({mimeType: 'audio/mp3', type: 'audio'})).data.url;

    console.log(uploadUrl)

    const file = await handleSpeakFile(text);

    await uploadfile(file, uploadUrl);

    const url = new URL(uploadUrl);
    url.search = "";
    console.log(url.toString())
    return url.toString();
}